html, body {
    font-family: 'Dongle', sans-serif;
    margin: 0;
    padding: 0;
  }
  
.dongle {
    font-family: 'Dongle', sans-serif;
}

  .text-container {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    font-size: 96px;
    color: white;
    opacity: 0.8;
    user-select: none;
    text-shadow: 1px 1px rgba(0,0,0,0.1);
  }
  
  :root {
    --color-bg1: rgb(108, 0, 162);
    --color-bg2: rgb(0, 17, 82);
    --color1: 18, 113, 255;
    --color2: 221, 74, 255;
    --color3: 100, 220, 255;
    --color4: 200, 50, 50;
    --color5: 180, 180, 50;
    --color-interactive: 140, 100, 255;
    --circle-size: 80%;
    --blending: hard-light;
  }
  
  @keyframes moveInCircle {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes moveVertical {
    0% {
      transform: translateY(-50%);
    }
    50% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(-50%);
    }
  }
  
  @keyframes moveHorizontal {
    0% {
      transform: translateX(-50%) translateY(-10%);
    }
    50% {
      transform: translateX(50%) translateY(10%);
    }
    100% {
      transform: translateX(-50%) translateY(-10%);
    }
  }
  
  .gradient-bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    background: #000300;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .gradient-bg svg {
    display: none;
  }
  
  .gradients-container {
    filter: url(#goo) blur(40px);
    width: 100%;
    height: 100%;
  }
  
  .g1,
  .g2,
  .g3,
  .g5
  {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    opacity: 1;
    z-index: 1;
  }
  
  .g1 {
    transform-origin: center center;
    animation: moveVertical 30s ease infinite;
  }
  
  .g2 {
    transform-origin: calc(50% - 400px);
    animation: moveInCircle 20s reverse infinite;
  }
  
  .g3 {
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);
    transform-origin: calc(50% + 400px);
    animation: moveInCircle 40s linear infinite;
  }
  
  .g4 {
    transform-origin: calc(50% - 200px);
    animation: moveHorizontal 40s ease infinite;
    background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 50%) no-repeat;
    opacity: 0.7;
  }
  
  .g5 {
    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));
    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: moveInCircle 20s ease infinite;
  }
  
  .interactive {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);
    width: 100%;
    height: 100%;
    top: -5%;
    left: -5%;
    opacity: 0.7;
    z-index: 12;
  }
  
  .hero-content {
    position: relative;
    z-index: 10; /* Higher than the background */
}

.gradient-bg2 {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #000300;
  z-index: 0; /* Ensure it's behind your content but above anything else it should cover */
}

.experience-content {
  position: relative;
  margin-top: -70vh;
  z-index: 1; /* Higher than the gradient background */
  /* Other styles remain unchanged */
}

.experience-card {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.card-content > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drawer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.gradient-bg2 svg {
  display: none;
}

.gradients-container2 {
  filter: url(#goo) blur(40px);
  width: 100%;
  height: 100%;
}

.g12,
.g22,
.g32,
.g52
{
  position: absolute;
  background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
  mix-blend-mode: var(--blending);
  width: var(--circle-size);
  height: var(--circle-size);
  top: calc(50% - var(--circle-size) / 2);
  left: calc(50% - var(--circle-size) / 2);
  opacity: 1;
  z-index: 1;
}

.g12 {
  transform-origin: center center;
  animation: moveVertical 30s ease infinite;
}

.g22 {
  transform-origin: calc(50% - 400px);
  animation: moveInCircle 20s reverse infinite;
}

.g32 {
  top: calc(50% - var(--circle-size) / 2 + 200px);
  left: calc(50% - var(--circle-size) / 2 - 500px);
  transform-origin: calc(50% + 400px);
  animation: moveInCircle 40s linear infinite;
}

.g42 {
  transform-origin: calc(50% - 200px);
  animation: moveHorizontal 40s ease infinite;
  background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 50%) no-repeat;
  opacity: 0.7;
}

.g52 {
  width: calc(var(--circle-size) * 2);
  height: calc(var(--circle-size) * 2);
  top: calc(50% - var(--circle-size));
  left: calc(50% - var(--circle-size));
  transform-origin: calc(50% - 800px) calc(50% + 200px);
  animation: moveInCircle 20s ease infinite;
}

.interactive2 {
  position: absolute;
  background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
  mix-blend-mode: var(--blending);
  width: 100%;
  height: 100%;
  top: -5%;
  left: -5%;
  opacity: 0.7;
  z-index: 12;
}
