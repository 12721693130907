@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  background-color: #000300;
  overflow-x: hidden;
}

.main-container {
  width: 100vw;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
}

.gradient-text {
  background: linear-gradient(
    90deg,
    #cb83ff,
    #8d83ff,
    #5a48ff,
    #8d83ff,
    #cb83ff,
    #8d83ff,
    #5a48ff
  );
  background-size: 300% 300%;
  background-clip: text;
}

/*create gradient for shadow*/
.gradient-shadow {
  background: linear-gradient(
    90deg,
    #cb83ff,
    #8d83ff,
    #5a48ff,
    #8d83ff,
    #cb83ff,
    #8d83ff,
    #5a48ff
  );
  background-size: 300% 300%;
  color: transparent;
}
.nav-item {
  position: relative;
  padding: 10px;
}

.nav-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #cb83ff, #8d83ff, #5a48ff);
  transition: width 0.2s ease-in-out;
}

.nav-item:hover::after {
  width: 100%;
}

.main-container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  max-width: 100%;
}

.section {
  scroll-snap-align: start;
  height: 100vh;
}

.line-spacing {
  line-height: 1.75; /* Adjust this value as needed */
}

.logo-container {
  display: flex;
  height: auto;
  /* Add overflow hidden to prevent flickering on width change */
  overflow: hidden;
}

.logo-container2 {
  display: flex;
  height: 100px;
  /* Add overflow hidden to prevent flickering on width change */
  overflow: hidden;
}

.logo-ap {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.8s ease; /* Smooth transition for the container */
}

.logo-a {
  font-weight: thin;
  display: inline-block;
  transition: transform 0.9s ease;
  transform: rotate(540deg); /* Set the A upside down initially */
  margin-bottom: 3.5px; /* Adjust as needed */
}

.logo-p {
  font-weight: thin;
  display: inline-block;
  transition: transform 0.8s ease-out;
  transform: translateX(-36px) rotate(-360deg);
}

.ani-part {
  font-weight: bold;
  color: white;
  display: inline-block;
  opacity: 0;
  transform: translateX(-35px); /* Start hidden to the left */
  transition: all 0.9s ease; /* Smooth transition for appearing */
  white-space: nowrap; /* Keep the text from wrapping */
}

.potts-part {
  font-weight: bold;
  color: white;
  display: inline-block;
  opacity: 0;
  transform: translateX(-50px); /* Start hidden to the left */
  transition: all 0.9s ease; /* Smooth transition for appearing */
  white-space: nowrap; /* Keep the text from wrapping */
}

.ani-part1,
.potts-part1 {
  font-weight: bold;
  color: white;
  display: inline-block;
  opacity: 0;
  transform: translateX(-55px); /* Start hidden to the left */
  transition: all 0.9s ease; /* Smooth transition for appearing */
  white-space: nowrap; /* Keep the text from wrapping */
}

.potts-part2 {
  font-weight: bold;
  color: white;
  display: inline-block;
  opacity: 0;
  transform: translateX(-70px); /* Start hidden to the left */
  transition: all 0.9s ease; /* Smooth transition for appearing */
  white-space: nowrap; /* Keep the text from wrapping */
}

.potts-part3 {
  font-weight: bold;
  color: white;
  display: inline-block;
  opacity: 0;
  transform: translateX(-90px); /* Start hidden to the left */
  transition: all 0.9s ease; /* Smooth transition for appearing */
  white-space: nowrap; /* Keep the text from wrapping */
}

.logo-ap:hover .logo-a {
  transform: rotate(0deg);
  transition: all 0.9s ease-in-out;
  /*slow down time for  text hover color change*/
  transition-delay: -0.3s;
  font-weight: bold;
  margin-bottom: 0;
  margin-left: 0;
}

.logo-ap:hover .logo-p {
  transform: translateX(0px) rotate(0deg); /* Slide in on hover */
  transition: all 0.8s ease-in-out;
  /*slow down time for  text hover color change*/
  transition-delay: -0.2s;
  font-weight: bold;
  margin-bottom: 0;
  margin-left: 0;
}

.logo-ap:hover .ani-part,
.logo-ap:hover .potts-part,
.logo-ap:hover .ani-part1,
.logo-ap:hover .potts-part1,
.logo-ap:hover .potts-part2,
.logo-ap:hover .potts-part3 {
  color: #5a48ff;
  font-weight: bold;
  opacity: 1;
  transform: translateX(0px); /* Slide in on hover */
  transition: all 0.6s ease-in-out;
}

.fade-bottom {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
}

.slick-slide > div {
  margin: 0 10px;
}

.thin {
  font-weight: 100;
}

.slider {
  display: flex;
  overflow: hidden;
  position: relative;
}

.slide {
  flex: 0 0 auto;
  width: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease;
}

@media (max-width: 768px) {
  .slide {
    /* Adjust sizes for smaller screens */
    height: 100vh;
  }
}

.custom-shape-divider-top-1737306688 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1737306688 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}

.custom-shape-divider-top-1737306688 .shape-fill {
  fill: #111827;
}

.custom-shape-divider-bottom-1737307193 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1737307193 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1737307193 .shape-fill {
  fill: #1f2937;
}

.custom-shape-divider-bottom-1739234793 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1739234793 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1739234793 .shape-fill {
    fill: #c7d2fe;
}